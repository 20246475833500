/* eslint-disable import/no-anonymous-default-export */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, styled } from "@mui/material";
import { CSSProperties, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constants from "../../utils/Constants";
import EfImageView from "../EfImageView";

export default function ({
  icon,
  type,
  href,
}: {
  icon: any;
  type: string;
  href: string;
}) {
  const styles: Record<string, CSSProperties> = {
    itemWrapper: {
      width: "100%",
      maxWidth: "100%",
      borderRadius: "10px",
      padding: "2px",
      alignSelf: "center",
    },
    imageTitleWrapper: {
      display: "flex",
      borderRadius: "50%",
      borderColor: Constants.colors.lightTheme.palette.primary.main,
      borderWidth: "5px",
      gap: 2,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    image: {
      border: `2px solid ${Constants.colors.lightTheme.palette.text.secondary}`,
      borderRadius: "50%",
      padding: 4,
      width: 60,
      height: 60,
      overflow: "hidden",
    },
    text: {
      textTransform: "capitalize",
      color: "white",
    },
  };

  const HoverComponent = styled(Box)(({ theme }) => {
    return {
      // backgroundColor: "#ffffff59",
      display: "flex",
      flexDirection: "row",
      borderRadius: 8,
      borderWidth: 1,
      border: "1px solid " + Constants.colors.lightTheme.palette.primary.main,
      backgroundColor:Constants.colors.lightTheme.palette.primary.main+"f0",
      padding: 8,
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",
      "&:hover": {
        backgroundColor:"#007899",
        cursor: "pointer",
      },
    };
  });

  return (
    <Box sx={styles.itemWrapper} onClick={() => (window.location.href = href)}>
      <HoverComponent>
        <Box sx={styles.imageTitleWrapper}>
          <EfImageView tint={"white"} sx={styles.image} src={icon} alt={type} />
          <p style={styles.text}> {type} </p>
        </Box>

        <ArrowForwardIcon color={"inherit"} />
      </HoverComponent>
    </Box>
  );
}
