import Constants from "../utils/Constants";
import { HBImageTint } from "./temp-ef-image-root";

export default function EfImageView({
  src = "",
  sx = {},
  alt = "",
  tint,
}: {
  src: string;
  sx?: React.CSSProperties;
  alt?: string;
  tint?: any | undefined;
}) {
  return (
    <HBImageTint
      sx={sx}
      alt={alt}
      src={src}
      color={tint ? tint : Constants.colors.lightTheme.palette.primary.main}
    />
  );
}
