import { Box, Container, Typography } from "@mui/material";
import { CSSProperties } from "react";
import LoginAsOptions from "../../components/home-components/LoginAsOptions";
import Constants from "../../utils/Constants";
import RedirectUrl from "../../utils/RedirectUrls";
import "./Home.css";
const Home = () => {
  const loginTypes: {
    icon: any;
    type: string;
    href: string;
  }[] = [
    {
      icon: Constants.images.admin,
      href: RedirectUrl.admin,
      type: "Admin",
    },
    {
      icon: Constants.images.campusAdmin,
      href: RedirectUrl.campusAdmin,
      type: "Campus Admin",
    },
    {
      icon: Constants.images.teacher,
      href: RedirectUrl.teacher,
      type: "Teacher",
    },
    {
      icon: Constants.images.parents,
      href: RedirectUrl.parents,
      type: "Parents",
    },
    {
      icon: Constants.images.student,
      href: RedirectUrl.student,
      type: "Student",
    },
  ];

  // useEffect(() => {
  //   if (load(Constants.key.CookiesKey.loginToken)) {
  //     navigate(Constants.navigationStack.dashboard);
  //   }
  // }, []);

  const homeStyle: Record<string, CSSProperties> = {
    loginAs: {
      marginTop: "100px",
      marginBottom: "20px",
      padding: "5px",
      borderRadius: "5px",
      textAlign: "center",
      color: Constants.colors.lightTheme.palette.primary.main,
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      background: "#3a1f0e14",
      height: "100vh",
    },
  };
  return (
    <Box sx={homeStyle.container}>
      <Container sx={homeStyle.wrapper}>
        <Typography variant="h3" sx={homeStyle.loginAs}>
          ISM SCHOOL
        </Typography>

        {loginTypes.map((v) => {
          console.log(v);
          return <LoginAsOptions  key={v.type} {...v} />;
        })}
      </Container>
    </Box>
  );
};

export default Home;
